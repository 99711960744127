<template>
  <v-row justify="center">
    <v-flex
        md12
    >
      <v-card-text class="text--primary"
                   style="text-align: center!important;font-size: 18px !important;margin-top: 50px">

        <v-icon style="color: green;font-size: 200px">mdi-progress-check</v-icon>
        <h1 style="padding-bottom: 100px;padding-top: 100px;color: teal">{{ $t("requestUpdateSuccess") }}</h1>
        <h1 style="padding-bottom: 100px;color: teal">{{ $t("thankYou") }}</h1>
      </v-card-text>
    </v-flex>

  </v-row>
</template>

<script>
export default {
  name: "Success",
  data() {
    return {}
  },

  created() {
    let vm = this;
    setTimeout(function () {
      vm.$router.push({name: "Home"});

    }, 1500);
  }
}
</script>